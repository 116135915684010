import cx from 'classnames';
import dynamic from 'next/dynamic';
import ReactPlayer from 'react-player';
import React, { useState } from 'react';

import { IconDownload } from '#styleguide/index';

import styles from './index.module.scss';

const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const Typography = dynamic(() => import('#app/UI/atoms/new-typography'));

const images = [
  './home/b2b-program/students_1.webp',
  './home/b2b-program/students_2.webp',
  './home/b2b-program/students_3.webp',
  './home/b2b-program/students_4.webp',
  './home/b2b-program/students_5.webp'
];

const data = [
  {
    title: 'Integración en el Currículo escolar',
    description: 'Dictamos las clases de tecnología en los últimos 3 años escolares.',
    bg: '#6BE3D7'
  },
  {
    title: (
      <>
        Clases virtuales <br className={styles.deskMob} /> en vivo{' '}
      </>
    ),
    description: 'Hacemos que las habilidades tecnológicas lleguen a todos los rincones.',
    bg: '#FFAA7D'
  },
  {
    title: (
      <>
        Formación <br className={styles.desktop} /> continua a docentes
      </>
    ),
    description: 'Para asegurar la sostenibilidad a largo plazo del proyecto.',
    bg: '#BFE1FC'
  }
];

const B2bProgram = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <section className={styles.container}>
      <Typography className={styles.title} tag="h2">
        Nos aliamos con gobiernos y fundaciones para{' '}
        <span className={styles.mainColor}>impulsar la educación en tecnología</span>
      </Typography>
      <div className={styles.containerImages}>
        {images.map((img, idx) => (
          <img
            key={`student-images-${idx}`}
            className={cx(
              styles.image,
              idx > 2 ? styles.desktop : styles.deskMob,
              idx === 1 || idx === 3 ? styles.mTop : styles.mBottom
            )}
            src={img}
            alt="estudiantes"
          />
        ))}
      </div>
      <div className={styles.containerCards}>
        <div className={styles.principalCard}>
          <div className={styles.text}>
            <Typography className={styles.titleCard} tag="p">
              Buscamos impactar a los estudiantes:
            </Typography>
            <ul>
              <li>
                <Typography className={styles.descriptionCard} tag="p">
                  Aumentando sus <b>conocimientos en tecnología.</b>
                </Typography>
              </li>
              <li>
                <Typography className={styles.descriptionCard} tag="p">
                  Fortaleciendo sus <b>habilidades socio-ocupacionales.</b>
                </Typography>
              </li>
              <li>
                <Typography className={styles.descriptionCard} tag="p">
                  <b>Reduciendo</b> la deserción y la repetición escolar.
                </Typography>
              </li>
              <li>
                <Typography className={styles.descriptionCard} tag="p">
                  Colaborando en su <b>ocupación laboral y educativa.</b>
                </Typography>
              </li>
            </ul>
          </div>
          <div className={styles.imageCard} onClick={() => setIsPlaying(prev => !prev)}>
            {!isPlaying ? (
              <img src="./home/b2b-program/main-card.webp" alt="estudiante" />
            ) : (
              <ReactPlayer
                url="https://youtu.be/-ei1GYi4YX0"
                playing={isPlaying}
                volume={0.5}
                width="100%"
                height="100%"
              />
            )}
          </div>
        </div>
        {data.map((item, idx) => (
          <div
            key={`card-${idx}`}
            className={styles.defaultCard}
            style={{ background: item.bg }}
          >
            <Typography className={styles.titleCard} tag="p">
              {item.title}
            </Typography>
            <Typography className={styles.descriptionCard} tag="p">
              {item.description}
            </Typography>
          </div>
        ))}
      </div>
      <Button
        className={styles.button}
        iconLeft={
          <div>
            <IconDownload fill="#000" />
          </div>
        }
        variant="black-outline"
        type="url"
        target="_blank"
        href="https://drive.google.com/file/d/1rmjiSShQh0KxjTOPGnNGet1z-XRJYP-T/view?usp=sharing"
        textOptions={{ fontSize: [20, 20], color: '#001F19' }}
      >
        Descargar más información
        <span className={styles.desktop}>&nbsp;sobre el programa</span>
      </Button>
    </section>
  );
};

export default B2bProgram;
